@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.cols {
  lost-flex-container: row;

  &.is-default {
    flex-wrap: nowrap;
  }

  @include media('sm-and-up') {
    flex-wrap: nowrap;
  }
}
.cols__img {
  .is-default & {
    flex-shrink: 0;

    @include media('xs-only') {
      width: 30%;
    }

    @include media('sm-and-up') {
      width: 188px;
    }
  }

  .is-wide-spotlight & {
    @include media('xs-only') {
      width: 100%;
    }

    @include media('sm-and-up') {
      flex-grow: 1;
      align-self: center;
      order: 1;
      border-radius: 6px !important;
      overflow: hidden;
      margin: 1rem;
    }
  }

  img {
    display: block;
    width: 100%;
  }
}
.cols__copy {
  // hide content on desktop within scrollable area
  @include media('sm-and-up') {
    overflow: hidden;
    scrollbar-gutter: stable;
    &:hover {
      overflow: auto;
    }
  }

  .is-default & {
    flex-grow: 1;

    @include media('sm-and-up') {
      max-height: 148px;
    }
  }
  .is-wide-spotlight & {
    width: 100%;

    @include media('xs-only') {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    @include media('sm-and-up') {
      margin-right: 0 !important;
      lost-column: 2/6;
    }
  }
}
.v-card__title {
  line-height: 1.2;
  word-break: normal;
}

.v-card__title,
.v-card__text {
  .is-wide-spotlight & {
    @include media('xs-only') {
      padding-left: 0;
    }
  }
}
